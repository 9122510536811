import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import UserCallBack from "./userPaymentCallBack";

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/weAcceptCallBack"
          render={(props) => <UserCallBack {...props} />}
        />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
