import React, { Component } from "react";
import "./weAccept.css";
import logo from "./logo.png";
export default class UserCallBack extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    const queryString = require("query-string");
    const parsed = queryString.parse(this.props.location.search);
    this.setState({ parsed });
  }

  render() {
    // let logo = require("./logo.png");
    var paymentProcess = "pending";
    console.log(this.state.parsed.success);
    if (this.state.parsed.success === "true") {
      paymentProcess = "succeeded";
    } else if (this.state.parsed.success === "false") {
      paymentProcess = "failed";
    }
    return (
      <div className="callBackWindow">
        <img className="callBackLogo" src={logo} alt="logo" />
        <h2>Curawella</h2>
        {console.log(this.state.parsed.success)}
        <h1 className="callBackSuccess">Payment process {paymentProcess}</h1>
        {this.state.parsed.success === "true" && (
          <h1 className="callBackMoney">
            Paid Amount is {parseInt(this.state.parsed.amount_cents) / 100}
          </h1>
        )}
      </div>
    );
  }
}
